import React, { FC } from 'react';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { HiddenDataStyled } from '@ui/hidden-data/hidden-data.styled';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { CurrencySymbol } from '@legacyApp/client/components/currencySymbol/currencySymbol';
import { AwesomeIcon } from '@common/components/icons/AwesomeIcon';
import { CurrencyType } from '@legacyApp/types/wallet/CurrencyType';

export const HiddenData: FC<{
	currency?: CurrencyType;
}> = ({ currency }) => {
	return (
		<HiddenDataStyled>
			<AwesomeIcon icon={faUserSecret} />
			<span>{trans({ label: 'Hidden' })}</span>
			{currency && <CurrencySymbol currency={currency} />}
		</HiddenDataStyled>
	);
};

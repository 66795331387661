import { useCallback } from 'react';
import { useUserId } from '../selectors/user.selectors';
import { useStateEffect } from './useStateEffect';

export const useIsMe = (id: string) => {
	const userId = useUserId();
	const checkIsMe = useCallback(() => {
		return id ? userId && id === userId : true;
	}, [id, userId]);
	return useStateEffect(checkIsMe);
};

import styled, { css } from 'styled-components';
import { CSSClassNameButton } from '@ui/button';

const getTheme = (props) => {
	if (props.maxAmount) {
		return css`
			.input {
				width: calc(100% - 60px);
			}

			.${CSSClassNameButton} {
				padding-left: 0;
				padding-right: 0;
				white-space: nowrap;
				width: 50px;
			}

			@media (min-width: ${props.theme.media.minWidthDesktop}) {
				.input {
					width: calc(100% - 90px);
				}

				.${CSSClassNameButton} {
					width: 80px;
				}
			}
		`;
	}
	if (props.localTheme && props.localTheme.sideButton) {
		return css`
			.input {
				border-bottom-right-radius: 0;
				border-top-right-radius: 0;
				width: calc(100% - 95px);
			}

			.input-children {
				float: right;
				padding: 0;
				width: 95px;

				.${CSSClassNameButton} {
					border-bottom-left-radius: 0;
					border-top-left-radius: 0;
					margin: 0;
					padding: 10px 0 !important;
					width: 100%;
				}
			}
		`;
	}
};

export interface InputWrapperStyleProps {
	localTheme?: Record<string, any>;
	className?: string;
	maxAmount?: boolean;
}

export const InputWrapperStyle = styled.div.attrs<InputWrapperStyleProps>({
	className: 'input-wrapper',
})<InputWrapperStyleProps>`
	width: ${(props) =>
		props.localTheme && props.localTheme.width ? props.localTheme.width : 100}%;
	float: left;
	padding: 5px 0;
	position: relative;

	> span {
		display: inline-block;
		width: 100%;
		float: left;
	}

	.input-children {
		padding: 5px 0;
		position: relative;
		font-family: ${(props) => props.theme.text.fonts.Bold};
		width: 100%;

		&-bottom {
			padding: 0 0 5px;
			float: left;
		}
	}

	${getTheme}
`;

import styled, { css } from 'styled-components';
import { CSSClassNameButton } from '@ui/button';
import {
	boxNoHeight,
	inputHeight,
} from '@legacyApp/client/modules/style/defaults';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';

export interface InputRelativeBoxStyleProps {
	isDisabled?: boolean;
	className?: string;
	isSideLabel?: string;
	componentName?: string;
	type?: string;
}

export const InputRelativeBoxStyle = styled.div.attrs<InputRelativeBoxStyleProps>(
	{
		className: 'relative',
	},
)<InputRelativeBoxStyleProps>`
	position: relative;
	${boxNoHeight};
	${inputHeight};

	${(props) =>
		props.isDisabled
			? css`
					cursor: default;
					pointer-events: none;

					img {
						opacity: 0.4;
					}

					> i,
					.buttons-box > i {
						color: ${(props) => props.theme.colors.textDisabled}!important;
					}

					> svg,
					.buttons-box > svg {
						fill: ${(props) => props.theme.colors.textDisabled}!important;
					}
			  `
			: ''}
	.buttons-box {
		align-items: center;
		display: flex;
		height: 100%;
		padding: 0;
		position: absolute;
		right: 4px;
		top: 0;
		gap: 4px;

		> span,
		> div {
			align-items: center;
			display: flex;
			height: 100%;
		}

		.${CSSClassNameButton} {
			border-radius: 2px;
			align-items: center;
			display: flex;
			height: calc(100% - 10px);
			justify-content: center;
			min-width: 35px;
			padding: 0 6px !important;
		}
	}

	&[data-sidelabel] {
		.input-side-label {
			display: flex;
			position: absolute;
			right: -22px;
			top: 50%;
			transform: translateY(-50%);
		}

		${chatViewport(
			(viewportPadding) => css`
				@media (max-width: ${(props) =>
						calcStyle({
							a: props.theme.media.maxWidthTablet,
							b: viewportPadding,
						})}) {
					.input-side-label {
						right: 10px;
					}

					.buttons-box {
						right: 39px;
					}
				}
			`,
		)}
	}

	${(props) =>
		props.type === 'checkbox' &&
		`
		display: flex;
		align-items: center;

		& input {
			width: unset;
			margin-right: 8px;
		}
		
	`}
`;

import { useCallback } from 'react';
import {
	modalHandlePrevious,
	openModal,
} from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { GameType } from '@modules/games/Game/constants/availableGames';
import { useModalId } from '@modules/modals/store/modal.selectors';
import { BetIdType } from '../../types/bets/BetIdType';
import { BetType } from '../../types/bets/BetType';
import { useAppDispatch } from '../store/useAppDispatch';

interface UseOpenBetModalPropsType {
	data?: {
		hash?: string;
		game?: {
			name?: GameType;
		};
	};
	id: BetIdType;
	game: GameType;
	type: BetType;
	elementQuery?: string;
}

interface UseOpenBetModalResponseType {
	openCallback: () => void;
}

export const useOpenBetModal = ({
	data,
	id,
	game,
	type,
}: // elementQuery,
UseOpenBetModalPropsType): UseOpenBetModalResponseType => {
	// const [element] = useRef<{current: HTMLElement}>(elementQuery ? document.querySelector(elementQuery) : null);
	const dispatch = useAppDispatch();
	const modalId = useModalId();

	const openCallback = useCallback(() => {
		const betId = data?.hash || id;
		const gameName = data?.game?.name || game;
		dispatch(
			openModal(
				type === MODAL_ID.SESSION ? MODAL_ID.SESSION : MODAL_ID.BET,
				'useOpenBetModal',
				{
					data,
					game: gameName,
					type,
					id: betId,
				},
			),
		);
		if (modalId) {
			dispatch(modalHandlePrevious());
		}
	}, [data, id, game, dispatch, type, modalId]);

	return {
		openCallback,
	};
};

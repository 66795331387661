import { connect } from 'react-redux';

import { showAlert } from '@legacyApp/client/store/alerts/alerts.actions';

import { InputWrapper } from '@legacyApp/client/components/input/inputWrapper';

const mapDispatchToProps = (dispatch) => {
	return {
		showAlert: (type, message) => dispatch(showAlert(type, message)),
	};
};

const InputWrapperContainer = connect(null, mapDispatchToProps)(InputWrapper);

export { InputWrapperContainer };

import React, { FC } from 'react';

import { HIDDEN_USER } from '@common/constants/user/HIDDEN_USER';
import { useIsMe } from '@common/hooks/useIsMe';
import { UserType } from '@legacyApp/types/user/UserType';
import { HiddenData } from '@ui/hidden-data';
import { UserName, UserNameOptions } from '@ui/user-name';

import { useMe, useUser } from '@common/components/user/UserLogin/useUser';
import { trans } from '@legacyApp/client/modules/translation/translate';

export interface UserLoginProps extends UserNameOptions {
	user?: UserType;
	loading?: boolean;
}

const UserName_: FC<UserLoginProps> = (props) => {
	const userNameProps = useUser(props);
	return <UserName {...userNameProps} />;
};

const UserMe_: FC<UserLoginProps> = (props) => {
	const userNameProps = useMe(props);
	return <UserName {...userNameProps} />;
};

export const UserLogin: FC<UserLoginProps> = (props) => {
	const isMe = useIsMe(props?.user?.uuid);

	if (props.loading) {
		return <div>{trans({ label: 'Loading' })}...</div>;
	}

	if (isMe) {
		return <UserMe_ {...props} />;
	}

	if (props.user.login === HIDDEN_USER) {
		return <HiddenData />;
	}

	return <UserName_ {...props} />;
};

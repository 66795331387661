import classnames from 'classnames';
import React, { FC, useCallback } from 'react';
import {
	StyledUserName,
	StyledUserNameText,
} from '@ui/user-name/user-name.styled';
import { UserNameIcons } from '@ui/user-name/user-name-icons';
import { RaceBadgeType } from '@legacyApp/types/user/UserType';
import { useStateEffect } from '@common/hooks/useStateEffect';

export interface UserNameOptions {
	prefix?: boolean;
	maxLength?: number;
	noClick?: boolean;
	noLevelIcon?: boolean;
	inline?: boolean;
	noBadges?: boolean;
	noName?: boolean;
	fullWidth?: boolean;
	className?: string;
	noStyles?: boolean;
	isOnline?: boolean;
}

export interface UserNameUser {
	vipLevel: number;
	admin: boolean;
	raceBadge: RaceBadgeType;
	moderator: boolean;
	highRoller: boolean;
	name: string;
}

export interface UserNameProps {
	user: UserNameUser;
	options: UserNameOptions;
	isMe: boolean;
	onClick?: () => void;
}

export const UserName: FC<UserNameProps> = ({
	user,
	isMe,
	options,
	onClick,
}) => {
	const {
		prefix,
		noClick,
		inline,
		noName,
		fullWidth,
		className,
		noStyles,
		maxLength,
	} = options;

	const parseName = useCallback(() => {
		const maxLength_ = maxLength === undefined ? 15 : maxLength;
		if (!maxLength_) {
			return user?.name;
		}
		return user?.name?.length > maxLength_
			? `${user?.name.slice(0, maxLength_)}...`
			: user?.name;
	}, [maxLength, user?.name]);
	const name = useStateEffect<string>(parseName);

	return (
		<StyledUserName
			$my={isMe && prefix}
			$inline={inline}
			$noStyles={noStyles}
			className={
				!noStyles &&
				classnames(className, {
					'width-auto': !fullWidth,
					'no-click': noClick,
				})
			}
			onClick={onClick}
		>
			<UserNameIcons user={user} options={options} isMe={isMe} />
			{!noName && (
				<StyledUserNameText className="text-overflow-ellipsis">
					{prefix && '@'}
					{name}
				</StyledUserNameText>
			)}
		</StyledUserName>
	);
};

import styled from 'styled-components';
import classnames from 'classnames';

import { currencyLogoStyle } from '@legacyApp/client/modules/style/mixins/currencyLogoStyle';

export const InputLabelStyle = styled.label.attrs(({ className }) => ({
	className: classnames('text-style-label-cap', className),
}))`
	color: var(--color-dark-100);
	display: flex;
	align-items: center;
	line-height: 1;
	margin-bottom: 4px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	span {
		line-height: 1;
		display: flex;
		justify-content: center;
		gap: 4px;
	}

	.currency-logo {
		${currencyLogoStyle({ size: '12px', important: true })}
	}

	@media (min-width: ${(props) => props.theme.media.minWidthBigDesktop}) {
		margin-bottom: 8px;
	}
`;

import { FC } from 'react';
import { IconProps } from '@icons/all-bets';
import { Svg } from '@legacyApp/client/components/svg/Svg';
import { useId } from '../../hooks/useId';

export const Copy: FC<IconProps> = ({
	fill,
	fillStop,
	fillMiddle,
	className,
}) => {
	const { get } = useId({ id: 'copy-icon' });
	const _fill = fill || 'white';
	const _fillMiddle = fillMiddle || 'white';
	const _fillStop = fillStop || 'white';
	return (
		<Svg
			className={className}
			content={
				<>
					<path
						d="M13.334 7.16634V5.49967C13.334 5.05765 13.1584 4.63372 12.8458 4.32116C12.5333 4.0086 12.1093 3.83301 11.6673 3.83301H5.00065C4.55862 3.83301 4.1347 4.0086 3.82214 4.32116C3.50958 4.63372 3.33398 5.05765 3.33398 5.49967V12.1663C3.33398 12.6084 3.50958 13.0323 3.82214 13.3449C4.1347 13.6574 4.55862 13.833 5.00065 13.833H6.66732M8.33398 7.16634H15.0006C15.9211 7.16634 16.6673 7.91253 16.6673 8.83301V15.4997C16.6673 16.4201 15.9211 17.1663 15.0006 17.1663H8.33398C7.41351 17.1663 6.66732 16.4201 6.66732 15.4997V8.83301C6.66732 7.91253 7.41351 7.16634 8.33398 7.16634Z"
						stroke={get().url}
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<defs>
						<linearGradient
							id={get().id}
							x1="4.67348"
							y1="3.833"
							x2="17.2137"
							y2="3.80454"
							gradientUnits="userSpaceOnUse"
						>
							<stop stopColor={_fill} />
							<stop offset="0.385417" stopColor={_fillMiddle} />
							<stop offset="0.932292" stopColor={_fillStop} />
						</linearGradient>
					</defs>
				</>
			}
			width={20}
			height={21}
			wrapperFill={'none'}
		/>
	);
};

import { useCallback } from 'react';
import { UserNameProps, UserNameUser } from '@ui/user-name';
import { useAppDispatch } from '@legacyApp/hooks/store/useAppDispatch';
import { useFriendsOnlineList } from '@legacyApp/client/store/friends/friends.selectors';
import {
	useUserId,
	useUserIsAdmin,
	useUserIsHighRoller,
	useUserIsModerator,
	useUserName,
	useUserRaceBadge,
} from '@common/selectors/user.selectors';
import { useVipLevel } from '@modules/vip/store/selectors/vip.selectors';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { openModal } from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { LoginNameType } from '@legacyApp/types/user/LoginNameType';
import transactionService from '../../../../modules/transactions/transactionsService';
import { UserLoginProps } from './UserLogin';

export const useIsOnline = (id: string) => {
	const onlineList = useFriendsOnlineList();
	return useStateEffect<boolean>(
		useCallback(() => {
			return onlineList.some((user) => user.uuid === id);
		}, [id, onlineList]),
	);
};

export const useOpenUserModal = ({
	uuid,
	name,
}: {
	uuid: string;
	name: LoginNameType;
}) => {
	const dispatch = useAppDispatch();
	return useCallback(() => {
		dispatch(
			openModal(MODAL_ID.USER_DETAILS, 'userName', {
				uuid,
				name,
			}),
		);
	}, [dispatch, uuid, name]);
};

export const useUser = (props: UserLoginProps): UserNameProps => {
	const { user, noClick, ...options } = props;

	const isOnline = useIsOnline(user?.uuid);

	const openModal_ = useOpenUserModal({
		uuid: user?.uuid,
		name: user?.login,
	});

	const user_: UserNameUser = useStateEffect(
		useCallback(() => {
			return {
				vipLevel: user?.vip_level,
				admin: user.is_admin,
				raceBadge: user.race_badge,
				moderator: user.is_moderator,
				highRoller: user.is_high_roller,
				name: typeof user.login === 'boolean' ? '' : user.login,
			};
		}, [
			user.is_admin,
			user.is_high_roller,
			user.is_moderator,
			user.login,
			user.race_badge,
			user?.vip_level,
		]),
		(user, prevUser) => !transactionService.isDuplicate(user, prevUser),
	);

	return {
		user: user_,
		onClick: openModal_,
		options: {
			...options,
			isOnline,
		},
		isMe: false,
	};
};

export const useMe = (props: UserLoginProps): UserNameProps => {
	const { user, noClick, ...options } = props;
	const userName = useUserName();
	const id = useUserId();
	const userRaceBadge = useUserRaceBadge();
	const userIsAdmin = useUserIsAdmin();
	const userIsModerator = useUserIsModerator();
	const userIsHighRoller = useUserIsHighRoller();
	const { level: vipLevel_ } = useVipLevel();

	const isOnline = useIsOnline(id);

	const openModal_ = useOpenUserModal({
		uuid: id,
		name: userName,
	});

	const user_: UserNameUser = useStateEffect(
		useCallback(() => {
			return {
				vipLevel: vipLevel_,
				raceBadge: userRaceBadge,
				admin: userIsAdmin,
				moderator: userIsModerator,
				highRoller: userIsHighRoller,
				name: userName,
			};
		}, [
			userIsAdmin,
			userIsHighRoller,
			userIsModerator,
			userName,
			userRaceBadge,
			vipLevel_,
		]),
		(user, prevUser) => !transactionService.isDuplicate(user, prevUser),
	);

	return {
		user: user_,
		onClick: openModal_,
		options: {
			...options,
			isOnline,
		},
		isMe: true,
	};
};

import styled from 'styled-components';

export const HiddenDataStyled = styled.span.attrs(() => ({
	className: 'hidden-data',
}))`
	display: flex !important;
	justify-content: flex-start;
	align-items: center;

	.fa-user-secret {
		margin-right: 5px;
		color: ${(props) => props.theme.colors.text};
	}

	> span {
		color: ${(props) => props.theme.colors.text};
	}

	svg {
		width: 1em;
	}

	.currency-logo {
		display: inline-flex !important;
		margin-left: 5px;
	}
`;
